import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout/Layout';
import { withTrans } from '../i18n/withTrans';
import { Link } from 'gatsby';
import { Card } from '@material-ui/core';
import { ClockIcon } from '../assets/customIcons';
import Favicon from '../assets/images/favicon-manatour-16x16.png';

const IndexPage = ({ t }) => {
  const goToReservationPage = () => {
    const isCookiesAccepted = localStorage.getItem('isCookiesAccepted');
    localStorage.clear();
    localStorage.setItem('isCookiesAccepted', isCookiesAccepted);
  };
  return (
    <Layout pageName="home">
      <Helmet>
        <title>{t('helmet.landing')}</title>
        <link
          rel="icon"
          type="image/png"
          href={Favicon}
          sizes="16x16"
          className="favicon"
        />
      </Helmet>
      <h1 className="landing-title">
        <span className="h1-before">{t('landingPage.title')}</span>
        {t('landingPage.title2')}
        <strong>{t('landingPage.title3')}</strong>
      </h1>
      <h2 className="landing-descr">{t('landingPage.description')}</h2>
      <div className="landing-content">
        <Card className="landing-infocard">
          <div className="row-1">
            <p className="info-title">{t('landingPage.infocard.tour')}</p>
            <p className="visit-duration">
              <span style={{ fontSize: 16, width: 60 }}>
                {t('landingPage.infocard.from')}
              </span>
              <ClockIcon />
            </p>
          </div>
          <div className="row-2">
            <div className="col">
              <p className="label">{t('landingPage.infocard.visitor.adult')}</p>
              <p className="price">
                {t('landingPage.infocard.visitor.full_price')}
              </p>
            </div>
            <div className="col">
              <p className="label">{t('landingPage.infocard.visitor.youth')}</p>
              <p className="price">
                {t('landingPage.infocard.visitor.reduced_price')}
              </p>
            </div>
            <div className="col">
              <p className="label">{t('landingPage.infocard.visitor.child')}</p>
              <p className="price">
                {t('landingPage.infocard.visitor.child_price')}
              </p>
            </div>
            <div className="col">
              <p className="label">{t('landingPage.infocard.visitor.free')}</p>
              <p className="price">
                {t('landingPage.infocard.visitor.free_price')}
              </p>
            </div>
          </div>
          <div className="row-3">
            <div className="col">
              <p>
                {t('landingPage.infocard.evening-start')}
                <span className="evening-time">
                  {t('landingPage.infocard.evening-time')}
                </span>
              </p>
            </div>
            <div className="col">
              <p className="label">{t('landingPage.infocard.evening-tour')}</p>
              <p className="price">{t('landingPage.infocard.evening-price')}</p>
            </div>
          </div>
        </Card>
        <button
          onClick={() => goToReservationPage()}
          className="primary landing-book-btn"
        >
          <Link to={'/reservation'}>{t('landingPage.buttons.book')}</Link>
        </button>
      </div>
    </Layout>
  );
};

export default withTrans(IndexPage);

IndexPage.propTypes = {
  t: PropTypes.func.isRequired
};
